<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <!-- 轮播图 -->
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/en-关于盛泰banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <div class="content">
      <div class="top_text">
        <el-card>
          <h2 class="H2">About Us</h2>
          <img class="img_1" src="@/assets/about/culture/企业文化图1.png" alt="">
          <img class="img_2" src="@/assets/about/culture/企业文化图2.png" alt="">
          <img class="img_3" src="@/assets/about/culture/en-修饰文字.png" alt="">
          <p v-for="item in text_top" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
        </el-card>
      </div>
      <div class="bottom_text">
        <el-card>
          <h2 class="H2">Corporate Cultures</h2>
          <img src="@/assets/about/culture/en-企业文化核心内容.png" alt="">
          <p v-for="item in text_bottom" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
        </el-card>
      </div>
    </div>

    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/en-关于盛泰banner.png" style="width: 100%;">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <div class="content">
      <h2>About Us</h2>
      <img class="img_1" src="@/assets/about/culture/企业文化图1.png" alt="">
      <img class="img_2" src="@/assets/about/culture/企业文化图2.png" alt="">
      <p v-for="item in text_top" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
     <h2>Corporate Cultures</h2>
     <img src="@/assets/about/culture/en-企业文化核心内容.png" alt=""/>
      <p v-for="item in text_bottom" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>

  </div>
</template>

<script>
import foot from '../components/foot.vue'
import headers from '../components/header.vue'
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { toRefs, reactive, ref, onMounted } from 'vue'
import { useRoute } from "vue-router";
export default {
  components: { foot, headers, move_foot, move_header },
  setup() {
    const Route = useRoute()
    const state = reactive({
      dataList: [],
      data: {},
      text_top: [
        { id: 1, text_: 'Shinetech was first established in Shenzhen in July 2006, followed by a company in Jiangxi in July 2010, Chongqing in April 2018, and India in 2019. There is already a integrated plan of Chongqing center (headquarters + base), Shenzhen center (R&D + sales), Jiangxi + India manufacturing base, Taiwan R&D center, Singapore R&D center, and the U.S. office. ' },
        { id: 2, text_: 'We have been maintaining rapid growth since our establishment. The output value has achieved 3.6 billion RMB in 2020 and exceeded 4 billion RMB in 2021, and providing more than 3,000 jobs for the society. We strive to attain an output value of 10 billion RMB in the next 3 to 4 years.' },
        { id: 3, text_: 'In 2020, we planned and put into construction the Shinetech Business Incubation Centre, which covers an area of about 173,000 ㎡. The first phase of construction investment of 1 billion RMB, with construction area of about 83000 ㎡, including product technology R&D center of 8000 ㎡, joint laboratory of 2000 ㎡, production center of 40,000 ㎡, supporting office building of 10,000 ㎡, and supporting living quarters of 23,000 ㎡. It was officially opened in 2022.' },
        { id: 4, text_: `Shinetech has built 85 modern production lines, more than 40 new COB chip packaging lines, and newly purchased a number of ultra-high precision AA production lines, with a camera production capacity of 500 million units per year. At present Shinetech's existing high-end camera module production workshops have all been upgraded to class 100 and class 1,000 dust-free workshops. At the same time, relying on the national strategy of "Made in China 2025", we are rapidly promoting automation and intelligent production, and referring to the standard of Industry 4.0. We are making rapid progress towards high-tech, intelligent and digital manufacturing enterprises.` }
      ],
      text_bottom: [
        { id: 1, text_: 'Shinetech, as one of the first camera module manufacturers in China, has strong R&D and manufacturing capabilities. Since establishment, Shinetech has been focusing on R&D, manufacturing, sales and service of cell phone camera modules, and extending to non-cell phone including notebook computer, vehicle-mounted, medical, AIOT and other fields, thus forming a new development pattern with cell phone camera module as the main field and non-cell phone camera in multiple fields. Currently, the shipment volume of camera modules ranks the fifth in the world.' },
        { id: 2, text_: 'The employees of Shinetech have been adhering to the core values of "Duty, endeavour, share and innovation" since its foundation. Our mission is to be based on the optoelectronic industry, become the best choice for international first-class brand customers. Our enterprise spirit is to find a group of kindred spirit and struggle lifetime for same path. Shinetech pursues the code of strivers-oriented, customer-focused, quality-basis, and sustainable development by technology and R&D. We hope to achieve the corporate vision of being stronger, farther, and being an everlasting company.' }
      ]
    })
    let methods = {}
    onMounted(() => {

    })
    return {
      ...toRefs(state), ...methods
    }
  }
}
</script>

<style scoped lang="less">
.box {

  width: 100%;

  .content {
    max-width: 1920px;
    margin: 50px auto;
    width: 70%;
    height: auto;
    p{font-size: 1.25rem;}
    .H2 {
      font-size: 1.7rem;
    }

    .H2::before {
      content: "";
      width: 30%;
      height: 2px;
      background-color: #c7000b;
      position: absolute;
      margin-top: 40px;
      left: 6%;
      transform: translateX(-50%);
      // top:55px;
      min-width: 35px;
      max-width: 35px;
    }

    .top_text {
      box-sizing: border-box;
      position: relative;

      p {
        padding: 0 30px;
        Letter-spacing: 1px;
      }

      .img_1,
      .img_2 {
        width: 49%;
        border-radius: 15px;
      }

      .img_1 {
        margin-bottom: 0px;
      }

      .img_2 {
        margin: -40px 0 40px 20px;
      }

      .img_3 {
        width: 100%;
        margin: -1.56rem 0 0 -0px;
      }
    }

    .bottom_text {
      margin-top: 30px;
      position: relative;

      img {
        width: 100%;
        margin: 20px 0;
      }

      p {
        padding: 0 30px;
        Letter-spacing: 1px;
      }
    }
  }
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .content {
      padding: 30px;
      box-sizing: border-box;
      .img_2{
        margin-top: 50px;
      }
      p{
        font-size: 60px;
      }
      img{
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
      }
            h2 {
        text-align: center;
        font-size: 80px;
      }



      h2::before {
        content: "";
        width: 30%;
        height: 8px;
        background-color: #c7000b;
        position: absolute;
        margin-top: 100px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        max-width: 100px;
      }
    }
  }
}
</style>